@import 'antd/dist/antd.css';

$primary-color: #123193;
$secondary-color: #00a652;
$light-secondary-color: #07CF5A;

* {
  user-select: none;
}

.layout {
  min-height: 100vh;
  background: white;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  text-align: center;
  padding: 0 20px;

  .header-builder {
    display: none;
  }

  .header-text {
    font-size: 40px;
    font-weight: 500;
  }
}

.content {
  display: flex;
  flex-grow: 1;
  background: whitesmoke;
  flex-direction: column;
  align-self: center;
  width: 100%;
  padding: 0;
  margin: 0;

  .stepper-menu-wrapper {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;

    .stepper-menu {
      display: none;
      width: 50%;
      min-width: 800px;
      flex-wrap: nowrap;
      margin-top: 15px;
      margin-bottom: 20px;
      align-self: center;
      align-items: center;

      .stepper-item {
        position: relative;
        display: flex;
        background: #C2C2C5;
        flex: 1;
        height: 50px;
        justify-content: center;
        align-items: center;
        padding-left: 30px;
        cursor: pointer;

        .stepper-text {
          color: white;
          font-size: 20px;
          font-weight: bold;
        }
      }

      .stepper-item:first-child {
        padding-left: 0px;
        border-radius: 25px 0 0 25px;
      }

      .stepper-item:last-child {
        padding-right: 20px;
        border-radius: 0px 25px 25px 0px;
      }

      .stepper-item:not(:first-of-type):before {
        content: '';
        position: absolute;
        left: 1px;
        top: 0;
        width: 0;
        height: 0;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-left: 25px solid white;
      }

      .stepper-item:not(:first-of-type):after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #C2C2C5
      }

      .selected2.stepper-item:not(:first-of-type):after {
        border-left: 25px solid #07CF5A;
      }

      .stepper-item.disabled {
        cursor: not-allowed;
      }
    }

    .stepper-item.selected {
      background: #07CF5A;
    }

    .mobile-stepper-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      width: 100%;
      background: $primary-color;
      height: 40px;
      color: white;
    }
  }

  .content-main-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 149px);
  }
}


@media only screen and (min-width: 769px) {
  .header {
    justify-content: space-between;

    .header-builder {
      display: block;
    }
  }
  .stepper-menu {
    display: flex !important;
  }
  .mobile-stepper-menu {
    display: none !important;
  }
}
