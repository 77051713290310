@import "../PumpBuilder.scss";

.truck-selector-wrapper {
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-self: center;
  border-top: 2px solid #bfbfbf;

  .truck-left-section {
    display: flex;
    width: 60%;
    background: white;


  }

  .truck-right-section {
    overflow-y: auto;
    overflow-x: hidden;
    width: 40%;
    height: calc(100% - 80px);
    background: white;

    .truck-card-list {
      .truck-brand-header {
        padding: 10px 10px 10px 30px;
        font-size: 30px;
        font-weight: 500;
        border-bottom: 2px solid lightgrey;
      }

      .truck-brand-header:not(:first-child) {
        border-top: 2px solid lightgrey;
        margin-top: 20px;
      }

      .truck-list {
        background: white;

        .ant-list-header {
          background: transparent;
          color: black;
          font-size: 20px;
          font-weight: 800;
        }
      }

      .truck-list-card {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 20px 40px 20px 50px;
        background: white;
        cursor: pointer;

        .ant-list-item {
          width: 100%;
        }

        .truck-card-radio {
          transform: scale(1.8);
          margin-right: 30px;

          .ant-radio-inner:after {
            background: $primary-color !important;
          }

          .ant-radio-checked .ant-radio-inner,
          .ant-radio:hover .ant-radio-inner,
          .ant-radio-wrapper:hover,
          .ant-radio-input:focus .ant-radio-inner {
            border-color: $primary-color !important;
          }
        }
      }

      .truck-card-text {
        margin-top: 5px;
        width: 100%;

        .truck-card-title {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 5px;
        }

        .truck-card-subtitle {
          margin-bottom: 18px;
          color: dimgray;
        }
      }

      .truck-list-card:not(:last-child):before {
        content: "";
        position: absolute;
        left: 100px;
        bottom: 0;
        height: 1px;
        width: 60%;
        border-bottom: 1px solid lightgrey;
      }

      .truck-list-card:hover {
        .truck-card-radio {
          .ant-radio-inner {
            border-width: 1.5px;
            border-color: black;
          }
        }
      }
    }


    .truck-next-button {
      position: fixed;
      bottom: 0;
      width: 40%;
      height: 80px;
      border-color: $primary-color;
      color: white;
      background: $primary-color;
      font-size: 20px;
      font-weight: 800;
      text-align: right;
      padding-right: 40px;
    }
  }
}

@media only screen and (min-width: 769px) {

  .truck-selector-wrapper {
    flex-direction: row;
  }
}
