@import "../PumpBuilder.scss";


.option-selector-wrapper {
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-self: center;
  border-top: 2px solid #bfbfbf;

  .option-left-section {
    display: flex;
    width: 60%;
    background: white;

    img {
      z-index: 5;
      display: none;
      width: 60%;
    }

    img.active {
      display: block;
    }
    svgt {
      position: absolute;
    }
  }

  .option-right-section {
    overflow-y: auto;
    overflow-x: hidden;
    width: 40%;
    height: calc(100% - 80px);
    background: white;

    .option-card-list {
      .option-brand-header {
        padding: 10px 10px 10px 30px;
        font-size: 30px;
        font-weight: 500;
        border-bottom: 2px solid lightgrey;
      }

      .option-brand-header:not(:first-child) {
        border-top: 2px solid lightgrey;
        margin-top: 20px;
      }

      .option-list {
        background: white;

        .ant-list-header {
          background: transparent;
          color: black;
          font-size: 20px;
          font-weight: 800;
        }
      }

      .option-card {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 20px 40px 20px 50px;
        background: white;
        cursor: pointer;

        .option-card-radio {
          transform: scale(1.8);
          margin-right: 30px;

          .ant-radio-inner:after {
            background: $primary-color !important;
          }

          .ant-radio-checked .ant-radio-inner,
          .ant-radio:hover .ant-radio-inner,
          .ant-radio-wrapper:hover,
          .ant-radio-input:focus .ant-radio-inner {
            border-color: $primary-color !important;
          }

          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $primary-color;
            border-color: $primary-color;
          }
          .ant-checkbox:after {
            border-color: $primary-color;
          }
          .ant-checkbox:hover .ant-checkbox-inner,
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: $primary-color !important;
          }
        }

        .option-card-text {
          margin-top: 5px;
          width: 100%;

          .option-card-title {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 5px;
          }

          .option-card-subtitle {
            margin-bottom: 18px;
            color: dimgray;
          }
        }
      }

      .option-list-card:not(:last-child):before {
        content: "";
        position: absolute;
        left: 100px;
        bottom: 0;
        height: 1px;
        width: 60%;
        border-bottom: 1px solid lightgrey;
      }

      .option-list-card:hover {
        .option-card-radio {
          .ant-radio-inner {
            border-width: 1.5px;
            border-color: black;
          }
        }
      }
    }

    .option-next-button {
      position: fixed;
      bottom: 0;
      width: 40%;
      height: 80px;
      border-color: $primary-color;
      color: white;
      background: $primary-color;
      font-size: 20px;
      font-weight: 800;
      text-align: right;
      padding-right: 40px;
    }
  }
}

@media only screen and (min-width: 769px) {
  .option-selector-wrapper {
    flex-direction: row;
  }
}
