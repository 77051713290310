@import "../PumpBuilder/PumpBuilder.scss";

.slideshow-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 100px 20px 0 20px;


  .slideshow-label-container {
    position: absolute;
    top: 30px;
    left: 30px;

    .slideshow-label {
      font-family: Garamond, Arial, sans-serif;
      letter-spacing: -3px;
      font-size: 48px;
      font-weight: 800;
    }

    .slideshow-sub-label {
      font-size: 21px;
      font-weight: 600;
    }
  }

  img {
    z-index: 5;
    display: none;
    width: 60%;
  }

  img.active {
    display: block;
  }

  .active {
    width: 80%;
  }

  .prev, .next {
    z-index: 10;
    width: 40px;
    font-size: 50px;
    user-select: none;
    color: $primary-color;
  }

  .slideshow-dots {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 40px;
    margin-left: -20px;
    z-index: 20;

    .slideshow-dot {
      cursor: pointer;
      height: 15px;
      width: 15px;
      margin: 0 2px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      transition: background-color 0.6s ease;
    }

    .dot-active, .slideshow-dot:hover {
      background-color: #717171;
    }
  }
}
