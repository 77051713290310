@import "../PumpBuilder.scss";

.summary-wrapper {
  padding: 0px 20px;
  overflow-y: auto;
  flex-wrap: nowrap;
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  border-top: 2px solid #bfbfbf;
  justify-content: space-evenly;

  .summary-left-section {
    flex: 1;
    max-width: 800px;
    background: transparent;

    .slideshow-container {
      background: white;
      padding: 0;
    }

    .summary-summary {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: normal;
      padding-left: 10px;
      .summary-summary-pump {
        font-size: 25px;
        font-weight: bold;
      }
      .summary-summary-truck {
        font-size: 25px;
        font-weight: bold;
      }
    }

    .summary-collapse-header {
      width: 100%;
      font-size: 20px;
      font-weight: 600;
      .summary-panel-content {
        font-size: 15px;
        font-weight: normal;
      }
    }
  }

  .summary-right-section {
    position: sticky;
    top: 0;
    flex: 1;
    width: fit-content;
    max-width: fit-content;
    display: flex;
    justify-content: center;
    background: white;

    .summary-form {
      padding: 30px 20px;
      background: white;
      height: fit-content;

      .ant-select-selector {
        box-shadow: 0 0 !important;
      }

      .ant-select-selection-search-input {
        display: none;
      }

      .ant-select-selector:hover, .ant-select-selector:focus,
      .ant-select-focused, .ant-select,
      .ant-input:hover, .ant-input:focus {
        border-color: $secondary-color !important;
        box-shadow: 0 0 !important;
      }

      .summary-form-title {
        font-size: 30px;
        font-weight: 700;
      }

      .ant-form-item {
        margin-bottom: 14px;

        .ant-form-item-label {
          padding: 0;

          label {
            font-size: 15px;
            font-weight: 600;
          }
        }
      }

      .summary-form-description {
        font-size: 15px;
        letter-spacing: -0.5px;
        margin-bottom: 10px;
      }

      .summary-country {
        .ant-radio-button-wrapper-checked, {
          border-color: $secondary-color !important;
          box-shadow: 0 0;
        }

        .ant-radio-button-wrapper:hover,
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          color: $secondary-color !important;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
          background-color: $secondary-color !important;
        }

        span {
          font-weight: 500;
        }
      }
    }

    .summary-next-button-wrapper {
      text-align: end;
      padding-right: 30px;
      .summary-next-button {
        width: 110px;
        height: 40px;
        border-radius: 20px;
        border: 2px solid $primary-color;
        background: $primary-color;
        color: white;
        font-size: 18px;
        font-weight: 400;
        padding: 0;
      }
    }
  }

}

@media only screen and (min-width: 769px) {
  .summary-wrapper {
    flex-direction: row;
    background: white;
    .summary-left-section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .summary-right-section {
      .summary-form {
        min-width: 450px;
        max-width: 550px;
      }
    }
  }
}
