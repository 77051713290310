@import "../PumpBuilder.scss";

.pump-model-selector-wrapper {
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-self: center;
  border-top: 2px solid #bfbfbf;

  .pump-model-left-section {
    display: flex;
    width: 60%;
    background: white;

    img.active {
      display: block;
    }
  }

  .pump-model-right-section {
    overflow-y: auto;
    overflow-x: hidden;
    width: 40%;
    height: calc(100% - 80px);
    background: transparent;
    .pump-model-next-button {
      position: fixed;
      bottom: 0;
      width: 40%;
      height: 80px;
      border-color: $primary-color;
      color: white;
      background: $primary-color;
      font-size: 20px;
      font-weight: 800;
      text-align: right;
      padding-right: 40px;
    }
  }

  .pump-model-card {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 20px 40px 20px 50px;
    background: white;
    cursor: pointer;

    .pump-model-card-radio {
      transform: scale(1.8);
      margin-right: 30px;

      .ant-radio-inner:after {
        background: $primary-color !important;
      }

      .ant-radio-checked .ant-radio-inner,
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-wrapper:hover,
      .ant-radio-input:focus .ant-radio-inner {
        border-color: $primary-color !important;
      }
    }

    .pump-model-card-text {
      margin-top: 5px;
      width: 100%;

      .pump-model-card-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .pump-model-card-subtitle {
        margin-bottom: 18px;
        color: dimgray;
      }
    }


    .pump-model-card-image-view-button {
      height: 100%;

      svg {
        font-size: 30px;
        color: gray;
      }

      svg:hover {
        color: #F40F02;
        transition: .2s;
      }
    }

    .pump-model-spec-view {
      text-align: center;
      color: $secondary-color;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .pump-model-card:before {
    content: "";
    position: absolute;
    left: 100px;
    bottom: 0;
    height: 1px;
    width: 60%;
    border-bottom: 1px solid lightgrey;
  }

  .pump-model-card:hover {
    .pump-model-card-radio {
      .ant-radio-inner {
        border-width: 1.5px;
        border-color: black;
      }
    }
  }
}

.ant-image-preview-operations {
  .ant-image-preview-operations-operation:nth-last-child(1),
  .ant-image-preview-operations-operation:nth-last-child(2) {
    display: none !important;
  }
}

@media only screen and (min-width: 769px) {
  .pump-model-selector-wrapper {
    flex-direction: row;
  }
}
