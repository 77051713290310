@import "../PumpBuilder.scss";

.pump-type-wrapper {
  width: 100%;

  .pump-type-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: content-box;
    width: 100%;
    height: 250px;
    transition: .3s;

    .pump-type-card-image-wrapper {
      text-align: center;
      width: 100%;
      background: transparent;
      cursor: pointer;
    }

    .pump-type-card-text {
      display: flex;
      width: 200px;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100px;
      line-height: 1;
      font-size: 30px;
      font-weight: 500;
      user-select: none;
    }

    .pump-type-card-button {
      margin-top: 10px;
      width: 90px;
      height: 60px;
      border-radius: 20px;
      border: 1px solid lightgrey;
      cursor: pointer;
      background: transparent;
      font-weight: 650;
      margin-bottom: 20px;
    }
  }
}

.pump-type-upside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;

  .pump-type-title {
    font-size: 40px;
    font-weight: 700;
  }

  .pump-type-subtitle {
    margin-top: -10px;
    font-size: 22px;
    font-weight: 400;
  }
}

@media only screen and (min-width: 769px) {
  .pump-type-upside {
    padding-bottom: 160px;

    .pump-type-title {
      margin-top: 40px;
      font-size: 60px;
    }
  }

  .pump-type-wrapper {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-self: center;
    justify-content: space-evenly;

    .pump-type-card {
      flex: 1;
      width: 250px;
      top: -100px;


      .pump-type-card-button {
        margin-bottom: 0;
      }
    }

    .pump-type-card:hover {
      flex: 1 1 10%;
      transition: .5s;

      img {
        transform: scale(1.2);
        transition: .5s;
      }

      .pump-type-card-button {
        font-weight: 800;
        border: 1px solid grey;
        transition: .5s;
      }
    }
  }
}
